/* Kartların genel stili */
.feature-card {
    background-color: #f8f9fa; 
    padding: 30px; 
    border-radius: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease; 
}

/* Kartların hover durumu */
.feature-card:hover {
    background-color: #999999; 
    color: #ffffff; 
    font-weight: bold;
    transform: translateY(-10px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}


/* Kart başlıkları */
.feature-card h3 {
    color: #343a40; 
    font-size: 24px; 
    margin-bottom: 20px; 
}

.feature-card:hover h3 {
    color: white; 
    font-size: 24px; 
    margin-bottom: 20px; 
}

/* Kart açıklamaları */
.feature-card p {
    color: #8f8f8f; 
    font-size: 16px;
}
.feature-card:hover p {
    color: white; 
    font-size: 16px; 
    margin-bottom: 20px; 
}

.feature-icon {
    font-size: 48px; 
    color: #d7b27f; 
    margin-bottom: 20px; 
}

@media (max-width: 768px) {
    .feature-card {
        margin-bottom: 20px; 
    }
}

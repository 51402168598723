.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: top 0.3s;
}

.navbar-fixed {
  top: 0;
}

.footer {
  width: 100%;
  background-color: #f2f2f2;
  padding: 20px 0;
  text-align: center;
}

.footeralt {
  width: 100%;
  background-color: #f2f2f2;
  padding: 20px 0;
  text-align: center;
}

.custom-list {
  list-style-type: none;
  justify-content: center;
  text-align: start;
}

.accordion-item {
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.accordion-button {
  background-color: #f8f9fa;
  color: #212529;
  font-weight: bold;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e9ecef;
}

.accordion-body {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}

.custom-container {

  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.custom-container ul,p{
  text-align: left;
  list-style-position: inside;
}

.App {
  text-align: center;
}

header {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

header nav ul {
  list-style: none;
  padding: 0;
}

header nav ul li {
  display: inline;
  margin-right: 20px;
}

article {
  margin-bottom: 30px;
}

aside {
  background-color: #f4f4f4;
  padding: 20px;
}

.subscribe-box input {
  margin-bottom: 10px;
}


.card {
  border: none;
  box-shadow: 0 4px 8px lightgray;
}

.card-title {
  font-size: 1.5em;
  color: #2c3e50;
  border-bottom: 2px solid #d7b27f;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.card-text {
  color: #555;
  line-height: 1.8;
}

.d-flex {
  display: flex;
}

.flex-fill {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.modal-dialog {
  max-width: none;
  width: calc(100% - 30px); 
}

.modal-body {
  overflow-x: hidden;
}

.custom-card {
  transition: transform 0.3s, background-color 0.3s;
}

.custom-card:hover {
  transform: scale(1.05);
  background-color: #e0e0e0; 

}
.custom-card-text {
  transition: color 0.3s; /* Yazı rengindeki geçiş efekti */
}

.custom-card:hover .custom-card-text {
  color: black;
}
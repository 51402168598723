/* detailsPhoto.css */

.image-gallery {
  display: flex;
  justify-content: space-around; 
  align-items: center;
  flex-wrap: wrap; 
}

.image-container {
  position: relative;
  width: 30%; 
  margin-bottom: 20px; 
  cursor: pointer;
}

.image-container.mobile-layout {
  width: 100%;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d7b27f;
  color: #8f8f8f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 20px;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.overlay-text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.mobile-text .overlay-text {
  font-size: 14px; /* Mobil cihazlarda daha küçük font boyutu */
}

.custom-navbar {
  background-color: #d9d9d9;
  color: grey;
  font-size: large;
}

.navbar-fixed {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.custom-navbar .nav-link {
  color: grey;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.custom-navbar .nav-link:hover {
  color: #d7b27f;
}

.custom-navbar .nav-link.active {
  border-bottom: 2px solid #d7b27f;
  color: #d7b27f;
}

.custom-navbar .navbar-toggler {
  order: 1;
}

.custom-navbar .social-icons {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.custom-navbar .nav-link {
  color: #6a6a6a;
  margin-right: 10px;
}

.social-icon {
  margin-left: 10px;
}

.service-menu-container {
  padding: 5px 10px 5px 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
}

.service-menu {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}

.service-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  width: 300px; /* Genişliği artırdık */
}

.service-menu-item:hover {
  background-color: #f0f0f0;
}

.service-image {
  width: 50px; /* Genişliği artırdık */
  height: 50px; /* Yüksekliği artırdık */
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px; /* Sağdaki boşluğu artırdık */
}

.service-title {
  font-weight: bold;
  font-size: 16px; /* Yazı boyutunu artırdık */
}

.dropdown-menu.show {
  display: block !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.btn-randevuOl {
  background-color: grey;
  color: white;
  border: none;
}

.btn-randevuOl:hover {
  background-color: #d7b27f;
  color: white;
  border: none;
}

@media (max-width: 768px) {
  .social-icons {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 15px;
  }

  .service-menu-container {
    padding: 5px;
    overflow-x: auto;
  }

  .service-menu-item {
    padding: 5px 10px; /* Mobilde padding'i küçülttük */
    width: 250px; /* Genişliği %100 yaparak daha küçük görünmesini sağladık */
  }

  .service-image {
    width: 40px; /* Mobilde genişliği küçülttük */
    height: 40px; /* Mobilde yüksekliği küçülttük */
    margin-right: 10px; /* Sağdaki boşluğu azalttık */
  }

  .service-title {
    font-size: 14px; /* Mobilde yazı boyutunu küçülttük */
  }
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 30px;
  background-color: #d9d9d9;
}

.owl-carousel .owl-item .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  height: 100%; 
}


.owl-carousel .owl-item .item:last-child {
  margin-left: 15px; /* Sadece son resme sol tarafta boşluk bırak */
}

.owl-nav, .owl-dots {
  display: none !important;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service-imagee {
  width: 100px; /* Küçük boyutlu resimler */
  height: 100px; 
  object-fit: contain; /* Resmin tamamını göster */
  border: none;
}

.title {
  color: gray; 
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  margin-top: 10px; 
}
